export function kftApiRequest(url: string, options = {}) {
  const config = useRuntimeConfig()
  const { apiBaseURL } = config.public

  const { $i18n: i18n } = useNuxtApp()
  const { locale: ISOLocale } = i18n
  const locale = convertISO2Locale(ISOLocale.value)
  const localeBCP47 = convertLocaleIntoBCP47(ISOLocale.value)

  const defaultOptions = {
    baseURL: apiBaseURL,
    method: 'POST',
    // cache request
    key: url,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${localeBCP47},${locale}`,
    },
  }

  const fetchOptions = { ...defaultOptions, ...options }

  return $fetch(url, fetchOptions)
}
